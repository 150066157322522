.cover-img {
    position: absolute;
    top:30%;
    left:30%;

}

.control {
    margin-left:0.2em;
    width:20px;
    height:20px;
    border: .01em solid white;
    font-size:15px;
    text-align: center;
}

.control:hover{
    filter: invert(60%);
}


.controls {
    position: absolute;
    bottom: 0%;
    left: -5%;
}

@media only screen and (max-width: 600px) {
    .controls {
       flex-direction: row;
       left: 40%;
       bottom: -20%;        
    }
    .control:hover{
        filter: invert(0);
    }

    .control:active{
        filter: invert(60%);
    }
    .control {
        font-size:30px;
        text-align: center;
        width:40px;
        height:40px;
        border: .01em solid white;
    }

}
@font-face {
  font-family: prestige-elite-std, monospace;
  font-weight: normal;
  font-style: normal;
  src: url("./Fonts/prestige/PrestigeEliteStd.otf");
}

.arrows {
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.arrows-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px 0;
}

.arrow {
  width: 2.4em;
  filter: invert(100%);
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin: 0 1px;
}


img {
  max-width: 100%;
  max-height: 100%;
}

.modal {
  font-family: prestige-elite-std, monospace;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 1s;
  animation-name: fadein;
  animation-duration: 1s;
}

.modal-header {
  direction: rtl;
  display: flex;
  justify-content: flex-start;
}

.modal-main {
  position: fixed;
  background: white;
  color: black;
  width: 50%;
  max-width: 350px;
  min-width:300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  border: 1px solid #dadada;
  box-shadow: 0 0 10px #9ecaed;
}

.modal-large {
  position: fixed;
  background: white;
  color: black;
  min-width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5em;
  border: 1px solid #dadada;
  box-shadow: 0 0 10px #9ecaed;
}

/* Media query for desktop devices */
@media screen and (min-width: 768px) {
  .modal-large {
    min-width: 40%;
  }
}


.display-block {
  display: block;
  z-index: 2;
}

.display-none {
  display: none;
}

.close-button {
  border: 0px solid white;
  text-transform: uppercase;
  right: 0;
  top: 0;
  outline: none;
  border-radius: 4px;
  background: transparent;
  opacity: 80%;
}

.exit {
  width: 2em;
  height: auto;
}

.close-button:hover {
  background-color: transparent;
  opacity: 50%;
}

/* Add Animation */
@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.help {
  border: 0px solid white;
  text-transform: uppercase;
  right: 0;
  bottom: 0;
  outline: none;
  border-radius: 4px;
  background: transparent;
  position: absolute;
  padding: 1em;
  z-index: 1;
  width: 50px;
}

.help img {
  filter: invert(30%);
  object-fit: contain;
}

.help:hover {
  filter: invert(0%);
}

.about {
  border: 0px solid white;
  left: 0;
  bottom: 0;
  outline: none;
  border-radius: 4px;
  background: transparent;
  position: absolute;
  padding: 1em;
  z-index: 1;
  font-weight: bold;
  text-align: justify;
  color: grey;
  max-width: 100%;
  font-size: 18px;
  animation: pulsate 3s infinite ease-in-out;
}

.about-title {
  text-align: left;
  font-size: 18px;
}
.about:hover {
  /* filter: invert(25%); */
  color: white;
}

.about-text {
  text-align: left;
  height: 500px;
  overflow-y: scroll;
  margin: 0 auto;
  overflow-wrap: break-word;
  word-wrap: break-word; /* Firefox support */
  font-size: 14px;
}

.about-artist {
  text-align: left;
  height: 80%;
  overflow-y: scroll;
  margin: 0 auto;
  overflow-wrap: break-word;
  word-wrap: break-word; /* Firefox support */
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 30px;
}

.enter {
    background-color: white !important;
    border: none;
    color: #ff5404;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    padding: 16px 16px;
    cursor: pointer;
    letter-spacing: 0em;
    font-family: "Courier New", Courier, monospace;
}


.enter:hover {
    filter: invert(4%);
    color: #ff8850;
}
.enterhover {
    filter: invert(4%);
    color: #ff8850;
}

@keyframes pulsate {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
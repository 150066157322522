.content {
    display: block;
}

.hide{
    display: none;
}

.thumbnail {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .thumbnail-hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }